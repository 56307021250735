.btc-multi-select-dropdown {
  background-color: transparent;
  min-width: 35ch;

  i {
    margin-right: 0.1rem;
  }

  img {
    margin-right: 0.1rem;
  }

  .btc-multi-select-dropdown__input-container {
    color: $default_color;
  }

  .btc-multi-select-dropdown__input {
    font-size: 0.75rem;
    color: $default-color;
  }

  .btc-multi-select-dropdown__control {
    background-color: transparent;
    border: 2px solid $default-color;
    border-radius: $btn-radius-round;
    color: $default-color;
    text-transform: uppercase;
    font-weight: $font-weight-semi;
    font-size: 0.75rem;
    line-height: 1.35rem;
    padding: 0.27rem 1.83rem;
    transition: 0.15s;
    cursor: text;
    box-shadow: none;

    &:hover {
      border: 2px solid $default-color;
    }
  }

  .btc-multi-select-dropdown__indicator {
    padding: 0;
  }

  .btc-multi-select-dropdown__indicators {
    margin-left: 3px;
  }

  .btc-multi-select-dropdown__menu {
    width: unset;
    border-radius: $border-radius-base;
    top: 125%;
    right: 7%;

    &:before {
      border-bottom: 11px solid $light-gray;
      border-left: 11px solid rgba(0, 0, 0, 0);
      border-right: 11px solid rgba(0, 0, 0, 0);
      content: "";
      display: inline-block;
      position: absolute;
      right: 12px;
      top: -12px;
    }

    &:after {
      border-bottom: 11px solid $white-color;
      border-left: 11px solid rgba(0, 0, 0, 0);
      border-right: 11px solid rgba(0, 0, 0, 0);
      content: "";
      display: inline-block;
      position: absolute;
      right: 12px;
      top: -11px;
    }
  }

  .btc-multi-select-dropdown__option {
    color: $default-color;
    font-size: 0.875rem;
    clear: both;
    white-space: nowrap;
    background-color: transparent;
    padding: 0.625rem 2.8125rem 0.625rem 0.9375rem;
    cursor: pointer;

    &:hover {
      color: white;
      background-color: $default-color;
    }
  }

  .btc-multi-select-dropdown__option--is-selected {
    color: white;
    background-color: $default-color;

    &:hover {
      background-color: $default-light-color;
    }
  }

  #clickableInput {
    caret-color: $default-color;
    font-weight: $font-weight-semi;
    text-transform: uppercase;
    line-height: 1.35rem;
  }

  .btc-multi-select-dropdown__value-container {
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    white-space: nowrap;
    display: inline-flex;
    text-overflow: ellipsis;
    flex-direction: row-reverse;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .select-button-inner-container {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    + .btc-multi-select-dropdown__input-container {
      max-width: 0;
    }
  }

  .dropdown-open:after {
    transform: rotate(180deg);
  }
}

@keyframes fadeIn {
  0% {
    transform: translate3d(12px, -40px, 0px);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
      opacity 0.3s ease 0s, height 0s linear 0.35s;
  }
  100% {
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
      opacity 0.3s ease 0s, height 0s linear 0.35s;
    transform: translate3d(0px, 0px, 0px);
  }
}

.btc-multi-select-dropdown__menu {
  animation: fadeIn 150ms linear;
}
