.btc-sticky-navbar {
  position: sticky;
  background-color: $default-body-bg;
  box-shadow: 0 0.25rem 0.25rem rgba($dark-font-color, 0.2);
}

.navbar .btn {
  margin-bottom: 0;
  margin-top: 0;
}

.btc-navbar-title {
  text-transform: capitalize;
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-weight: $font-weight-semi;
  color: $dark-font-color;
  margin: 0;
}

.navbar .btc-navbar-group {
  gap: 0.75rem;
}

@media screen and (max-width: 991px) {
  .navbar .btc-navbar-group .dropdown {
    min-width: 95%;
    margin: auto;
    display: block;
  }

  .btc-multi-select-dropdown {
    min-width: 95%;
    margin: auto;
    display: block;
  }

  .navbar .btc-navbar-group .btn {
    min-width: 100%;
  }
}
