.table {
  .img-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }

  .img-row {
    max-width: 60px;
    width: 60px;
  }

  .form-check {
    margin: 0;

    & label .form-check-sign::before,
    & label .form-check-sign::after {
      top: -17px;
      left: 4px;
    }
  }

  .btn {
    margin: 0;
  }

  small,
  .small {
    font-weight: $font-weight-light;
  }

  > thead > tr > th {
    font-size: 0.875rem;
    font-weight: $font-weight-bold;
    padding-bottom: 0;
    text-transform: uppercase;
    border: 0;
  }

  .radio,
  .checkbox {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    width: 15px;

    .icons {
      position: relative;
    }

    label {
      &:after,
      &:before {
        top: -17px;
        left: -3px;
      }
    }
  }

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 12px 7px;
    vertical-align: middle;
  }

  .th-description {
    max-width: 150px;
  }

  .td-price {
    font-size: 1.625rem;
    font-weight: $font-weight-light;
    margin-top: 5px;
    position: relative;
    top: 4px;
    text-align: right;
  }

  .td-total {
    font-weight: $font-weight-bold;
    font-size: $font-size-h5;
    padding-top: 20px;
    text-align: right;
  }

  .td-actions .btn {
    margin: 0px;
  }

  > tbody > tr {
    position: relative;
  }
}

.table-shopping {
  > thead > tr > th {
    font-size: $font-size-h6;
    text-transform: uppercase;
  }

  > tbody > tr > td {
    font-size: $font-size-default;

    b {
      display: block;
      margin-bottom: 5px;
    }
  }

  .td-name {
    font-weight: $font-weight-normal;
    font-size: 1.3125rem;

    small {
      color: $dark-gray;
      font-size: 0.6875rem;
      font-weight: $font-weight-light;
    }
  }

  .td-number {
    font-weight: $font-weight-light;
    font-size: $font-size-h4;
  }

  .td-name {
    min-width: 200px;
  }

  .td-number {
    text-align: right;
    min-width: 170px;

    small {
      margin-right: 3px;
    }
  }

  .img-container {
    width: 120px;
    max-height: 160px;
    overflow: hidden;
    display: block;

    img {
      width: 100%;
    }
  }
}

.table-responsive {
  overflow: scroll;
  padding-bottom: 10px;
}

#tables .table-responsive {
  margin-bottom: 30px;
}

.table-hover > tbody > tr:hover {
  background-color: $very-light-gray;
}

.ReactTable tfoot tr.rt-tfoot {
  flex-direction: row;

  .rt-td {
    border: none;
  }
}

.ReactTable .rt-th.username-column,
.ReactTable .rt-td.username-column {
  flex: 1.5 1;
}

.ReactTable .rt-th.account-column,
.ReactTable .rt-td.account-column {
  flex: 2 0;
}

.ReactTable .rt-th.actions-column,
.ReactTable .rt-td.actions-column {
  flex: 1 1;
}

.ReactTable .rt-th.resource-column,
.ReactTable .rt-td.resource-column {
  flex: 2 0;
}

.ReactTable .rt-th.costs-column,
.ReactTable .rt-td.costs-column {
  flex: 1.5 0;
}

.ReactTable .rt-th.eventtype-column,
.ReactTable .rt-td.eventtype-column {
  flex: 5 1;
}

.ReactTable .rt-th.source-column,
.ReactTable .rt-td.source-column {
  flex: 1.8 0;
}

.ReactTable .rt-th.norm-column,
.ReactTable .rt-td.norm-column {
  flex: 1.4 1;
}

.ReactTable .rt-th.count-column,
.ReactTable .rt-td.count-column {
  flex: 1 1;
}

.ReactTable .rt-th.account-id-column,
.ReactTable .rt-td.account-id-column {
  flex: 1.2 0;
}

.ReactTable .rt-th.budget-name-column,
.ReactTable .rt-td.budget-name-column {
  flex: 2 1;
}

.ReactTable .rt-th.accounts-column,
.ReactTable .rt-td.accounts-column {
  flex: 3 1;
}

.ReactTable .rt-th.used-limit-in-column,
.ReactTable .rt-td.used-limit-in-column, {
  flex: 1 1;
}

.ReactTable .rt-th.used-in-column,
.ReactTable .rt-td.used-in-column, {
  flex: 1 1;
}

.ReactTable .rt-th.level-column,
.ReactTable .rt-td.level-column, {
  flex: 1.5 1;
}


.ReactTable .rt-td.level-column, {
  text-align: center;
}

.ReactTable .rt-thead th.rt-resizable-header {
  overflow: hidden;
  text-overflow: ellipsis;
}