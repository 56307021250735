.login-logo {
  padding: 2.5rem 0 1.2rem;
  max-width: max-content;
  margin: 0 auto;

  img {
    max-width: 80%;
    display: block;
    margin: 0 auto;
  }

  p {
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.875rem;
  }
}

@media (min-width: 576px) {
  .login-logo {
    padding: 3.5rem 0 1.2rem;
  }
}
