button,
input,
optgroup,
select,
textarea {
  font-family: $sans-serif-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-normal;
}

a {
  color: $primary-color;

  &:hover,
  &:focus {
    color: $primary-color;
  }
}

h1,
.h1 {
  font-size: $font-size-h1;
  line-height: 1.15;
  margin-bottom: $margin-large;

  small {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    opacity: 0.8;
  }
}

h2,
.h2 {
  font-size: $font-size-h2;
  margin-bottom: $margin-large;
}

h3,
.h3 {
  font-size: $font-size-h3;
  margin-bottom: $margin-large;
  line-height: 1.4em;
}

h4,
.h4 {
  font-size: $font-size-h4;
  line-height: 1.45em;
  margin-top: $margin-large;
  margin-bottom: $margin-base;

  & + .category,
  &.title + .category {
    margin-top: -10px;
  }
}

h5,
.h5 {
  font-size: $font-size-h5;
  line-height: 1.4em;
  margin-top: 1.25rem;
  margin-bottom: $margin-small;
}

h6,
.h6 {
  font-size: $font-size-h6;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

p {
  &.description {
    font-size: 1rem;
  }
}

i.fa-fix {
  min-width: 38px;
  text-align-last: center;
}

i.fa-btc-like {
  display: inline-block;
  border-radius: $btn-radius-round;
  background-color: $info-color;
  color: $dark-font-color;
  box-shadow: 0px 0px 2px #888;
  padding: 0.5em 0.6em;
}

.title {
  font-weight: $font-weight-bold;

  &.title-up {
    text-transform: uppercase;

    a {
      color: $black-color;
      text-decoration: none;
    }
  }

  & + .category {
    margin-top: -10px;
  }
}

.description,
.card-description,
.footer-big p,
.card .footer .stats {
  color: $light-black;
  font-weight: $font-weight-light;
}

.category,
.card-category {
  text-transform: capitalize;
  font-weight: $font-weight-normal;
  color: $light-black;
  font-size: $font-size-mini;
}

.card-category {
  font-size: $font-size-h6;
}

.text-primary,
a.text-primary:focus,
a.text-primary:hover {
  color: $primary-color !important;
}

.text-info,
a.text-info:focus,
a.text-info:hover {
  color: $info-color !important;
}

.text-success,
a.text-success:focus,
a.text-success:hover {
  color: $success-color !important;
}

.text-warning,
a.text-warning:focus,
a.text-warning:hover {
  color: $warning-color !important;
}

.text-danger,
a.text-danger:focus,
a.text-danger:hover {
  color: $danger-color !important;
}

.text-gray,
a.text-gray:focus,
a.text-gray:hover {
  color: $light-gray !important;
}

// BTC
.has-text-btc-blue {
  color: $dark-font-color !important;
}
