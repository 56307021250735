@import "node_modules/bootstrap-scss/functions";
@import "node_modules/bootstrap-scss/variables";
@import "node_modules/bootstrap-scss/mixins/_breakpoints";

.btc-region-map__map {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.btc-region-map__container {
  width: 100%;
  height: 100%;
}
