/*!

 =========================================================
 * Paper Dashboard PRO React - v1.2.0 based on Paper Dashboard 2 Pro - v2.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import "paper-dashboard/variables";
@import "paper-dashboard/mixins";

// Plugins CSS
@import "paper-dashboard/plugins/plugin-animate-bootstrap-notify";
@import "paper-dashboard/plugins/plugin-perfect-scrollbar";
@import "paper-dashboard/plugins/plugin-jquery.jvectormap";
@import "paper-dashboard/plugins/plugin-bootstrap-switch";

// Core CSS
@import "paper-dashboard/buttons";
@import "paper-dashboard/inputs";
@import "paper-dashboard/typography";
@import "paper-dashboard/misc";
@import "paper-dashboard/navbar";
@import "paper-dashboard/dropdown";
@import "paper-dashboard/alerts";
@import "paper-dashboard/tables";
@import "paper-dashboard/footers";

// components
@import "paper-dashboard/checkboxes-radio";
@import "paper-dashboard/badges";
@import "paper-dashboard/pagination";
@import "paper-dashboard/modals";
@import "paper-dashboard/sidebar-and-main-panel";

// cards
@import "paper-dashboard/cards";
@import "paper-dashboard/cards/card-stats";

@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import "paper-dashboard/responsive";

// react differences
@import "paper-dashboard/react/react-differences";

// CUSTOM BTC
@import "paper-dashboard/custom";
