.primary-text,
.dropdown-item.primary-text {
  color: $primary-color;
}

.danger-text {
  color: $danger-color;
}

.warning-text {
  color: $info-color;
}

.btc-shorten-overflow-text {
  float: left;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.btc-shorten-overflow-text:hover span {
  position: absolute;
  overflow: visible;
  background-color: white;
  padding-right: 0.5em;
}

.icon-big {
  font-size: 2.625rem;
  min-height: 64px;

  i {
    line-height: 59px;
  }
}

.icon-right {
  float: right;
  margin-right: 0.75rem;
}

.btc-alert {
  i {
    margin-right: 0.5em;
  }
}

.updated-label {
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  margin-bottom: 0.5em;
}

.small-checkbox {
  .form-check-input[type="checkbox"] {
    position: static;
    cursor: pointer;
  }

  label {
    color: inherit;
    font-size: inherit;
    margin-bottom: 0;
    margin-left: 0.25rem;
    cursor: pointer;
  }
}

.badge {
  .badge-close {
    padding-right: $badge-padding-x;
    padding-left: $badge-padding-x;
    margin-right: -$badge-padding-x;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: inherit;
    color: inherit;
    text-shadow: none;
  }
}

.btc-gap {
  gap: 0.75rem;

  .badge {
    margin-bottom: 0;
  }
}

.btn.btn--no-margin {
  margin: 0;
}

.error {
  color: $danger-color;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: $primary-color;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: $primary-color;
}

.tooltip {
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    max-width: 20rem;
    background-color: $primary-color;
    opacity: 1;
  }
}

.anomalies-cost-user-prompt {
  display: flex;
  padding: 1rem;
  font-size: 1rem;
}

.btc-azure-logo {
  height: 1em;
  vertical-align: middle;
  display: inline;
  margin-top: -0.2em;
  margin-left: 0.25em;
}

.btc-tooltip-paragraph {
  padding: 0.5em;
  margin: 0;
  text-align: left;
}

.btc-description {
  max-width: 70ch;
}
