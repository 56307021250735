$table-min-width: 57rem;

@media only screen and (max-width: 800px) {
  .ReactTable.prevent-too-small-columns .rt-tr {
    min-width: $table-min-width;
  }
  .ReactTable.prevent-too-small-columns .rt-tfoot {
    min-width: $table-min-width;
  }

  .main-panel > .content {
    padding: 0 $padding-base !important;
  }
}
.prevent-too-small-columns {
  .rt-thead,
  .rt-tbody,
  .rt-tfoot {
    min-width: $table-min-width;
  }
}

.rt-td {
  white-space: break-spaces !important;
}

.ReactTable .react-table__form-group {
  .form-check-input[type="radio"] {
    position: static;
    cursor: pointer;
  }

  label {
    color: inherit;
    font-size: inherit;
    margin-bottom: 0;
    cursor: pointer;
  }
}

.ReactTable .rt-th.btc-10p-column,
.ReactTable .rt-td.btc-10p-column {
  flex: 0 1 10%;
}

.ReactTable .form-check {
  padding-left: 1.25rem;
  margin-bottom: 0;

  .form-check-label {
    padding-left: 0.5rem;
  }
}
