// font-family
$sans-serif-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;

// colors with prefix _BTC are the colors taken from the BTC webpage
// private colors (don't use directly elsewhere)

$_BTC-dunkelblau-color: #00325e;
$_BTC-gelb-color: #fbd200;
$_BTC-hellblau-color: #3273dc;
$_BTC-cyan-color: #5aa6e7;

// font colors

$white-color: #ffffff !default;

$font-color: $_BTC-hellblau-color !default;
$dark-font-color: $_BTC-dunkelblau-color !default;
$fill-font-color: $white-color;

// background colors / general colors

$default-color: $_BTC-hellblau-color !default;
$default-light-dark-color: darken($default-color, 10%) !default;
$default-dark-color: $_BTC-dunkelblau-color !default;
$default-light-color: lighten($default-color, 15%) !default;
$default-color-opacity: rgba($default-color, 0.3) !default;

$primary-color: $_BTC-hellblau-color !default;
$primary-light-dark-color: darken($primary-color, 10%) !default;
$primary-dark-color: $_BTC-dunkelblau-color !default;
$primary-light-color: lighten($primary-color, 15%) !default;
$primary-color-opacity: rgba($primary-color, 0.3) !default;

$success-color: $_BTC-hellblau-color !default; // Wouldn't it be clearer if success would be green or so???
$success-light-dark-color: darken($success-color, 10%) !default;
$success-dark-color: $_BTC-dunkelblau-color !default;
$success-light-color: lighten($success-color, 15%) !default;
$success-color-opacity: rgba($success-color, 0.3) !default;

$info-color: $_BTC-gelb-color !default;
$info-light-dark-color: darken($info-color, 5%) !default;
$info-dark-color: darken($info-color, 20%) !default;
$info-light-color: lighten($info-color, 15%) !default;
$info-color-opacity: rgba($info-color, 0.3) !default;

$warning-color: #eca202 !default;
$warning-light-dark-color: darken($warning-color, 10%) !default;
$warning-dark-color: darken($warning-color, 35%) !default;
$warning-light-color: lighten($warning-color, 15%) !default;
$warning-color-opacity: rgba($warning-color, 0.3) !default;

$danger-color: #de1f16 !default;
$danger-light-dark-color: darken($danger-color, 10%) !default;
$danger-dark-color: darken($danger-color, 35%) !default;
$danger-light-color: lighten($danger-color, 15%) !default;
$danger-color-opacity: rgba($danger-color, 0.3) !default;

$general-green-color: #28a745;

$link-disabled-color: #666666 !default;

$very-light-gray: #f5f5f5 !default;
$light-gray: #e3e3e3 !default;
$medium-gray: #dddddd !default;
$dark-gray: #9a9a9a !default;
$light-black: #444 !default;
$black-color: #2c2c2c !default;

$table-line-color: #ccc !default;

$opacity-gray-3: rgba(222, 222, 222, 0.3) !default;
$opacity-gray-5: rgba(222, 222, 222, 0.5) !default;
$opacity-gray-8: rgba(222, 222, 222, 0.8) !default;

$opacity-1: rgba(255, 255, 255, 0.1) !default;
$opacity-2: rgba(255, 255, 255, 0.2) !default;
$opacity-3: rgba(255, 255, 255, 0.3) !default;
$opacity-5: rgba(255, 255, 255, 0.5) !default;
$opacity-8: rgba(255, 255, 255, 0.8) !default;

$default-body-bg: #f4f3ef !default;

// shadows

$dropdown-shadow: 1px 2px 7px 1px rgba(0, 0, 0, 0.125);
$box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);

// font sizes

$font-size-mini: 0.625rem !default;
$font-size-small: 0.75rem !default;
$font-size-default: 0.875rem !default; //renamed from font-size-base since that had conflict with bootstrap
$font-size-large: 1rem !default;

$font-size-h1: 3.0625rem !default; // ~ 49px
$font-size-h2: 2.1875rem !default; // ~ 35px
$font-size-h3: 1.75rem !default; // ~ 28px
$font-size-h4: 1.5rem !default; // ~ 24px
$font-size-h5: 1.1875rem !default; // ~ 19px
$font-size-h6: 0.875rem !default; // ~ 14px

$font-size-large-navbar: 1.25rem !default;

// font weight

$font-weight-light: 200 !default;
$font-weight-normal: 400 !default;
$font-weight-semi: 600 !default;
$font-weight-bold: 700 !default;

// border styles
$border-thin: 1px solid !default;

$border-width-thin: 1px !default;
$border-width-thick: 0.125rem !default;

$border-radius-none: 0px !default;
$border-radius-extra-small: 0.125rem !default;
$border-radius-small: 0.1875rem !default;
$border-radius-base: 0.25rem !default;
$border-radius-large: 0.375rem !default;
$border-radius-x-large: 0.5rem !default;
$border-radius-xx-large: 0.75rem !default;
$border-radius-extreme: 0.875rem !default;

$btn-radius-round: 9999px !default;

// inner paddings for elements (like buttons, links)

$padding-input-vertical: 0.79em !default;
$padding-input-horizontal: 0.79em !default;

$padding-btn-vertical: 0.92em !default;
$padding-btn-horizontal: 1.83em !default;

$padding-element-vertical: 0.5rem !default;
$padding-element-horizontal: 0.7rem !default;

$padding-element-large-vertical: 0.9375em !default;
$padding-element-large-horizontal: 3em !default;

$padding-element-small-vertical: 0.417em !default;
$padding-element-small-horizontal: 1.25em !default;

$navbar-padding-base: 0.625rem;

$padding-dropdown-vertical: 10px !default;
$padding-dropdown-horizontal: 15px !default;

// paddings for container (like modals, cards or the page)

$padding-very-small: 0.3125rem !default; //5px
$padding-small: 0.625rem !default; //10px
$padding-base: 0.9375rem !default; //15px
$padding-large: 1.875rem !default; //30px
$padding-very-large: 2.8125rem !default; //45px

$padding-card: $padding-base !default;

// margins

$margin-very-small: $padding-very-small !default;
$margin-small: $padding-small !default;
$margin-base: $padding-base !default;
$margin-large: $padding-large !default;
$margin-very-large: $padding-very-large !default;

$margin-card: $margin-base !default;

// button icons sizes

$btn-icon-size: 3.5rem !default;
$btn-icon-size-regular: 2.375rem !default;
$btn-icon-font-size-regular: 0.9375rem !default;
$btn-icon-font-size-small: 0.6875rem !default;
$btn-icon-size-small: 1.875rem !default;
$btn-icon-font-size-lg: 1.325rem !default;
$btn-icon-size-lg: 3.6rem !default;

// transition / animation

$general-transition-time: 300ms !default;
$fast-transition-time: 150ms !default;
$transition-ease: ease 0s;

$topbar-x: topbar-x !default;
$topbar-back: topbar-back !default;
$bottombar-x: bottombar-x !default;
$bottombar-back: bottombar-back !default;

// sidebar
$sidebar-width: 16.25rem;
$sidebar-mini-width: 5rem;
$main-panel-width: calc(100% - #{$sidebar-width}) !default;
$main-panel-if-sidebar-mini-width: calc(100% - #{$sidebar-mini-width}) !default;

// misc

$line-height-nav-link: 1.625rem !default;
