.wrapper {
  overflow-x: hidden;
}

.sidebar,
.off-canvas-sidebar {
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: $sidebar-width;
  left: 0;
  z-index: 999;

  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    overflow: auto;
    width: $sidebar-width;
    z-index: 4;
    padding-bottom: 100px;

    .dropdown .dropdown-backdrop {
      display: none !important;
    }

    .navbar-form {
      border: none;
    }

    > .nav,
    .user .info {
      [data-toggle="collapse"] ~ div > ul > li > a {
        span {
          display: inline-block;
          @extend .animation-transition-general;
        }

        i {
          font-size: 1.0625rem;
          line-height: 1.25rem;
          width: 1.625rem;
        }
      }
    }
  }

  .logo {
    a.logo-mini {
      margin-left: 12px;
    }
  }

  .user {
    .photo {
      margin-right: 12px;
    }
  }
}

.main-panel {
  background-color: $default-body-bg;

  > .map-content {
    min-height: calc(100vh - 123px);
    margin-top: 63px;
  }
}

@media (min-width: 991px) {
  .sidebar-mini {
    .sidebar {
      z-index: 10000;
    }
  }
}

// Stying for logos

.sidebar .logo .simple-text .logo-img {
  img {
    height: 51%;
  }
}
