.card,
.card-shadow {
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}

.card {
  border-radius: $border-radius-extreme;
  background-color: $white-color;
  color: $dark-font-color;
  margin-bottom: 1.25rem;
  position: relative;
  border: 0 none;

  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;

  .card-body {
    padding: $padding-card $padding-card $padding-card * 0.667 $padding-card;

    &.table-full-width {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .card-header {
    &:not([data-background-color]) {
      background-color: transparent;
    }

    padding: $padding-card $padding-card 0;
    border: 0;

    .card-title {
      margin-top: 10px;
    }
  }

  .map {
    border-radius: $border-radius-small;

    &.map-big {
      height: 400px;
    }
  }

  &[data-background-color="orange"] {
    background-color: $primary-color;

    .card-header {
      background-color: $primary-color;
    }

    .card-footer {
      .stats {
        color: $white-color;
      }
    }
  }

  &[data-background-color="red"] {
    background-color: $danger-color;
  }

  &[data-background-color="yellow"] {
    background-color: $warning-color;
  }

  &[data-background-color="blue"] {
    background-color: $info-color;
  }

  &[data-background-color="green"] {
    background-color: $success-color;
  }

  .image {
    overflow: hidden;
    height: 200px;
    position: relative;
  }

  .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: $margin-card;
  }

  .numbers {
    font-size: 1.75rem;
  }

  .big-title {
    font-size: 0.75rem;
    text-align: center;
    font-weight: $font-weight-semi;
    padding-bottom: 1em;
  }

  label {
    font-size: $font-size-small;
    margin-bottom: 5px;
    color: $light-black;
  }

  .card-footer {
    background-color: transparent;
    border: 0;

    .stats {
      :not(.btn) i {
        margin-right: 5px;
        position: relative;
        top: 0px;
        color: $font-color;
      }
    }

    .btn {
      margin: 0;
    }
  }
}
