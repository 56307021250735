.btc-error-page {
  display: flex;
  min-height: 100vh;
  margin: 0;
  background-color: $default-body-bg;
}

.btc-two-column-grid {
  display: grid;
  grid-template-areas: "icon text";
  gap: 4em;
  align-items: center;
}

.btc-two-column-grid > div:first-child {
  grid-area: icon;
}

.btc-two-column-grid > div:last-child {
  grid-area: text;
}

.btc-error-page > div {
  margin: auto;
}

.btc-error-page h2 {
  font-size: $font-size-h2;
  color: $dark-font-color;
}

.btc-error-page p {
  font-size: 1rem;
}

.btc-error-page-icon {
  font-size: 8rem;
  color: $dark-font-color;
}

.error-page-logo-text {
  margin-top: 0.1em;
  font-size: $font-size-h5;
}
