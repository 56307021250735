%card-stats {
  hr {
    margin: $margin-very-small $margin-base;
  }
}

.card-text-uncapitalized {
  color: $dark-font-color;
  font-size: 1rem;
  line-height: 1.4em;
}

.card-stats {
  .card-body,
  .btc-card-title {
    padding: $padding-card $padding-card 0;

    .numbers {
      text-align: left;
      font-size: 1.75rem;

      p {
        margin-bottom: 0;
      }

      .card-category {
        color: $dark-font-color;
        font-size: 1rem;
        line-height: 1.4em;
      }
    }
  }

  .card-footer {
    padding: 0 $padding-card $padding-card;

    .stats {
      color: $font-color;
    }

    hr {
      margin-top: $margin-card * 0.66;
      margin-bottom: $margin-card;
    }
  }

  .icon-big {
    font-size: 2.625rem;
    min-height: 64px;

    i {
      line-height: 59px;
    }
  }

  .icon-small {
    font-size: 0.875rem;
    // min-height: 64px;

    // i{
    //     line-height: 59px;
    // }
  }

  .icon-head {
    font-size: 1.3125rem;
    line-height: 1em;
    // min-height: 64px;
  }

  .btc-fa-sizing {
    height: 14px;
    width: 14px;
    text-align: center;
    // min-height: 64px;
  }
}