@media screen and (max-width: 991px) {
  .dropdown.show .dropdown-menu {
    display: block !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }

  .nav-open {
    .menu-on-right {
      #bodyClick {
        left: auto;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .sidebar {
    .sidebar-wrapper {
      li > [data-toggle="collapse"] + div .nav li.active a {
        &:before {
          top: -2px;
        }

        &:after {
          top: -1px;
        }
      }

      li.active {
        > [data-toggle="collapse"] + div .nav li {
          &:before,
          &:after {
            display: none !important;
          }
        }

        > a:not([data-toggle="collapse"]) {
          &:before {
            border-right: 1.125rem solid $medium-gray;
            border-top: 1.125rem solid transparent;
            border-bottom: 1.125rem solid transparent;
            content: "";
            display: inline-block;
            position: absolute;
            right: -0.9375rem;
            opacity: 1;
            top: 0.375rem;
            transition: opacity 150ms ease-in;
          }

          &:after {
            border-right: 1.0625rem solid $default-body-bg;
            border-top: 1.0625rem solid transparent;
            border-bottom: 1.0625rem solid transparent;
            content: "";
            display: inline-block;
            position: absolute;
            right: -0.9375rem;
            opacity: 1;
            top: 0.4375rem;
            transition: opacity 150ms ease-in;
          }
        }
      }
    }
  }
}
