//  ========================================================================
//  bootstrap-switch - v3.3.2
//  http://www.bootstrap-switch.org
// ========================================================================
//  Copyright 2012-2013 Mattia Larentis
// http://www.apache.org/licenses/LICENSE-2.0
//


// IMPORTANT! Creative Tim Notice: this file has major changes to fit the Now Ui Dashboard 's design. If you want to use the plugin as it was before our changes, please get the old files from http://www.bootstrap-switch.org


.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 1.875rem;
  border: 0;
  position: relative;
  text-align: left;
  overflow: hidden;
  line-height: 0.5rem;
  width: 3.8125rem !important;
  height: 1.625rem;
  outline: none;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.btc-switch-wrapper {
  height: fit-content;
  width: fit-content;
  border-radius: 1.625rem;
  border: 2px solid $primary-color;
  background-color: $primary-color;
  display: inline-block;
}

.btc-switch-wrapper.disabled {
  opacity: 0.7;
}

.bootstrap-switch .bootstrap-switch-container {
  display: inline-flex;
  top: 0;
  height: 1.625rem;
  border-radius: 0.25rem;
  // Browser style method
  //noinspection CssUnknownProperty
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 6.25rem !important;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  color: $primary-color;
  padding: 0.375rem 0.625rem;
  font-size: 0.7rem;
  font-weight: $font-weight-semi;
  text-indent: -0.3125rem;
  line-height: 0.9375rem;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;

  i {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
}

.bootstrap-switch-on .bootstrap-switch-default {
  color: white;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
  float: left;
  width: 50% !important;
  background-color: $default-body-bg;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: $primary-color;
  background: white;
}

.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  color: #333333;
  width: 1.375rem !important;
  height: 1.375rem !important;
  margin: 0.125rem -0.6875rem;
  border-radius: 0.75rem;
  position: relative;
  float: left;
  padding: 0;
  background-color: $primary-color;
  box-shadow: 0 1px 1px $primary-color inset, 0 1px 1px rgba(0, 0, 0, 0.25);
  transition: 0.25s;
}

.bootstrap-switch-on .bootstrap-switch-label {
  background-color: white;
  box-shadow: 0 1px 1px white inset, 0 1px 1px rgba(0, 0, 0, 0.25);
}

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch .bootstrap-switch-handle-off {
  text-indent: 0.375rem;
}

.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
  height: auto;
}

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 0.0625rem 0.3125rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 0.3125rem 0.625rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 0.375rem 1rem;
  font-size: 1.125rem;
  line-height: 1.33;
}

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default;
}


.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.5s;
  transition: margin-left 0.5s;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
  margin-left: -0.125rem !important;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
  margin-left: -2.3125rem !important;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-handle-off {
  background-color: $default-dark-color;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-default {
  background-color: $primary-color;
}

.bootstrap-switch.wrapper {
  height: auto;
}