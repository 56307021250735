.btc-flex-security-level {
  flex: 1 1 !important;
  text-align: center;
}

.btc-flex-security-eventtype {
  flex: 1 0 20% !important;
}

.btc-security-ul {
  text-align: center;
  margin: 0;
  list-style-type: none;
  padding: 0;
}

.btc-security-table-btn {
  background-color: transparent !important;
  text-align: left;
}

.btc-security-table-normBadge {
  cursor: pointer;
}
