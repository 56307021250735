@import "node_modules/bootstrap-scss/functions";
@import "node_modules/bootstrap-scss/variables";
@import "node_modules/bootstrap-scss/mixins/_breakpoints";

.btc-card {
  min-height: calc(100% - 1.25rem);
  margin-bottom: 1.25rem;
}

.btc-card .card {
  height: 100%;
  width: 100%;
  margin-bottom: 0;
}

.btc-card--aspect-ratio {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.btc-card--aspect-ratio.btc-two-card-cells {
  position: static;
  padding-top: 0;
}

.btc-card--aspect-ratio:not(.btc-two-card-cells) > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@include media-breakpoint-up(sm) {
  .btc-card--aspect-ratio.btc-two-card-cells {
    position: relative;
    padding-top: calc(50% - 15px);
  }

  .btc-card--aspect-ratio > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.btc-card-title {
  padding: $padding-base;
}

.btc-card-title + .card-body {
  padding-top: 0;
}

.btc-stretch {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@include media-breakpoint-up(sm) {
  .btc-two-card-cells .btc-stretch.btc-stretch--row-over-sm {
    flex-direction: row;
  }
}

.btc-stretch__stretched {
  flex: 1 1 auto;
  max-height: 100%;
}

.btc-title-link {
  color: $dark-font-color;
  cursor: pointer;
}

.btc-title-link:hover {
  color: $font-color;
  text-decoration: none;
}

.card-footer .badge {
  margin-bottom: 0;
}

.btc-small-card-error {
  font-size: 1.375rem;
}

.card-category {
  margin-bottom: 0;
  margin-top: 0;
}

.card-title,
.card .card-header .card-title {
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.5em;
}

.table-header-dropdown-menu {
  text-align: right;
  max-width: min-content;
  position: absolute;
  right: 0;
}

.btc-card-vh-40 {
  min-height: 40vh;
}