// https://stackoverflow.com/a/50224067

// Override grid settings since we target more bigger screens

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 992px,
  lg: 1200px,
  xl: 1650px,
);

$container-max-widths: (
  sm: 540px,
  md: 960px,
  lg: 1140px,
  xl: 1580px,
);

// Import BT sources
@import "../../../node_modules/bootstrap-scss/bootstrap.scss";
@import "../../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";
